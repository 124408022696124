@import '/packages/site/src/public/styles/colors.scss';

.hint {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;

  .icon {
    width: 14px;
    height: 14px;
    border: 1px solid $ui_01;
    border-radius: 50%;
    font-size: 11px;
    display: inline-block;
    line-height: 12px;
    text-align: center;
  }

  .text {
    display: none;
    position: absolute;
    margin-left: 25px;
    font-size: 14px;
    line-height: 24px;
    background: $ui_01;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 32%);
    border-radius: 8px;
    color: $text_01;
    padding: 8px;
    font-weight: normal;
    min-width: 320px;
    margin-top: -7px;
    z-index: 1;
  }

  &:hover .text {
    display: inline-block;
  }

  &.withoutCircle {
    position: relative;

    .icon {
      border: 0;
      width: auto;
      height: auto;
    }

    .text {
      width: max-content;
      margin-left: 0;
      left: 0;
      bottom: 100%;
      right: 0px;
      min-width: auto;
    }

    &:last-of-type {
      .text {
        max-width: 256px;
        left: -148px;
      }
    }
  }
}