.hint {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.hint .icon {
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 11px;
  line-height: 12px;
  display: inline-block;
}

.hint .text {
  color: #05174b;
  z-index: 1;
  background: #fff;
  border-radius: 8px;
  min-width: 320px;
  margin-top: -7px;
  margin-left: 25px;
  padding: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  display: none;
  position: absolute;
  box-shadow: 0 1px 4px #00000052;
}

.hint:hover .text {
  display: inline-block;
}

.hint.withoutCircle {
  position: relative;
}

.hint.withoutCircle .icon {
  border: 0;
  width: auto;
  height: auto;
}

.hint.withoutCircle .text {
  width: max-content;
  min-width: auto;
  margin-left: 0;
  bottom: 100%;
  left: 0;
  right: 0;
}

.hint.withoutCircle:last-of-type .text {
  max-width: 256px;
  left: -148px;
}
/*# sourceMappingURL=index.bb54cdc7.css.map */
